import React, {useState} from 'react';
import {Button, Col, DatePicker, Drawer, Form, message, Radio, Row, Select, TimePicker} from 'antd';
import styles from "./createBatchClassDrawer.module.scss";
import moment, {Moment} from 'moment';
import {PlusOutlined} from '@ant-design/icons';
import {useUserList} from "../../../api/user/userApi";
import {createOfflineClassForBatch} from "../../../api/batch/batch";
import {useFormHandler} from "../../../utils/useFormHandler";
import dayjs from "dayjs";

interface CreateBatchClassDrawerProps {
    onClose: () => void;
    open: boolean;
    batch: any;
}

interface TimeSlot {
    startTime: any;
    endTime: any;
}

const CreateBatchClassDrawer: React.FC<CreateBatchClassDrawerProps> = ({open, onClose, batch}) => {
    const [form] = Form.useForm();
    const [selectedDays, setSelectedDays] = useState<string[]>([]);
    const [dayTimeSlots, setDayTimeSlots] = useState<Record<string, TimeSlot[]>>({});
    const {data: users, loading, error, fetchWithParams} = useUserList();

    const onFinish = useFormHandler(
        async (values: any) => {
            let formattedValues;
            // Check if class_type is "one_time"
            if (values.class_type === 'one_time') {
                // Extract the day name from the date
                const dayOfWeek = moment(values.date).format('ddd'); // 'Mon', 'Tue', etc.
                // Format the values for a one-time class
                formattedValues = {
                    ...values,
                    batch: batch.id,
                    time_slots: [{
                        day: dayOfWeek,  // Use the extracted day name as the key
                        schedules: [{
                            start_time: dayjs(values.start_time).format("HH:mm:ss"),
                            end_time: dayjs(values.end_time).format("HH:mm:ss"),
                        }]
                    }]
                };
            } else {
                // Format the values for regular classes (with selected days and time slots)
                formattedValues = {
                    ...values,
                    batch: batch.id,
                    time_slots: selectedDays.map(day => ({
                        day,
                        schedules: dayTimeSlots[day] && dayTimeSlots[day]
                            .filter(slot => slot.startTime && slot.endTime) // Filter out null values
                            .map(slot => ({
                                start_time: slot.startTime.format("HH:mm:ss"),
                                end_time: slot.endTime.format("HH:mm:ss"),
                            })),
                    })).filter(slot => slot.schedules && slot.schedules.length > 0), // Ensure we only send days with schedules
                };
            }
            await createOfflineClassForBatch(formattedValues);
        },
        form,
        () => {
            message.success('Offline classes created successfully!');
            onClose();
        },
        () => {
            message.error('Something went wrong');
        }
    );

    const toggleDay = (day: any) => {
        setSelectedDays((prevDays) => {
            if (prevDays.includes(day)) {
                return prevDays.filter((d) => d !== day);
            } else {
                return [...prevDays, day];
            }
        });
    };

    const addTimeSlot = (day: string) => {
        setDayTimeSlots((prevSlots) => ({
            ...prevSlots,
            [day]: [...(prevSlots[day] || []), {startTime: null, endTime: null}],
        }));
    };

    const handleTimeChange = (day: string, index: number, field: 'startTime' | 'endTime', value: Moment | null) => {
        const updatedSlots = [...dayTimeSlots[day]];
        updatedSlots[index] = {
            ...updatedSlots[index],
            [field]: value,
        };
        setDayTimeSlots({
            ...dayTimeSlots,
            [day]: updatedSlots,
        });
    };


    return (
        <Drawer
            title="Create Batch Class"
            width="40%"
            onClose={onClose}
            open={open}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                initialValues={{
                    class_type: 'regular', // Set the default value
                }}
            >
                <Form.Item name="class_type" label="Class type" initialValue="regular">
                    <Radio.Group>
                        <Radio value="regular">Regular Class</Radio>
                        <Radio value="one_time">One-time Class</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    name="faculty"
                    label="Faculty"
                    rules={[{required: true, message: 'Please select a faculty!'}]}
                >
                    <Select placeholder="Select Faculty"
                            options={users.map((user: any) => ({
                                value: user.id,
                                label: user.full_name,
                            }))}>
                    </Select>
                </Form.Item>

                {/* Conditionally render Days and Time Slots */}
                <Form.Item noStyle dependencies={['class_type']}>
                    {({getFieldValue}) => {
                        const classType = getFieldValue('class_type');
                        return classType === 'regular' ? (
                            <Form.Item name="days" label="Days" noStyle dependencies={['class_type']}>
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day) => (
                                        <Button
                                            key={day}
                                            type={selectedDays.includes(day) ? 'primary' : 'default'}
                                            onClick={() => toggleDay(day)}
                                        >
                                            {day}
                                        </Button>
                                    ))}
                                </div>
                            </Form.Item>
                        ) : (
                            <div>
                                <Form.Item
                                    name="date"
                                    label="Start Date"
                                    rules={[{required: true, message: 'Please select a start date!'}]}
                                >
                                    <DatePicker style={{width: '100%'}} format="YYYY-MM-DD"/>
                                </Form.Item>
                                <Form.Item
                                    name="start_time"
                                    label="Start Time"
                                    rules={[{required: true, message: 'Please select a start time!'}]}
                                >
                                    <TimePicker style={{width: '100%'}} format="HH:mm"/>
                                </Form.Item>
                                <Form.Item
                                    name="end_time"
                                    label="End Time"
                                    rules={[{required: true, message: 'Please select an end time!'}]}
                                >
                                    <TimePicker style={{width: '100%'}} format="HH:mm"/>
                                </Form.Item>
                            </div>
                        );
                    }}
                </Form.Item>

                {/* Dynamic Time Slots for Selected Days */}
                {form.getFieldValue('class_type') === 'regular' && selectedDays.map((day) => (
                    <div key={day} style={{
                        marginBottom: '25px',
                        marginTop: '20px',
                        padding: '10px',
                        backgroundColor: '#f5f5f5',
                        borderRadius: '8px'
                    }}>
                        <h4>{day}</h4>
                        {dayTimeSlots[day]?.map((slot: any, index) => (
                            <Row key={index} gutter={30} style={{marginBottom: 20}}>
                                <Col span={12}>
                                    <TimePicker
                                        placeholder="Select Start Time"
                                        value={slot?.startTime}
                                        format="HH:mm"
                                        style={{width: "100%"}}
                                        onChange={(time: any) => handleTimeChange(day, index, 'startTime', time)}
                                    />
                                </Col>
                                <Col span={12}>
                                    <TimePicker
                                        style={{width: "100%"}}
                                        placeholder="Select End Time"
                                        value={slot.endTime}
                                        format="HH:mm"
                                        onChange={(time: any) => handleTimeChange(day, index, 'endTime', time)}
                                    />
                                </Col>
                            </Row>
                        ))}
                        <Button
                            type="link"
                            icon={<PlusOutlined/>}
                            onClick={() => addTimeSlot(day)}
                            style={{marginTop: '10px'}}
                        >
                            Add Another Option
                        </Button>
                    </div>
                ))}

                {/* Submit Button */}
                <div className={styles.formFooter}>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </div>
            </Form>
        </Drawer>
    );
};

export default CreateBatchClassDrawer;
