import React, { useState } from 'react';
import styles from './profile.module.scss';
import { Button, Form, Input, InputNumber, message, Select } from 'antd';
import UploadField from '../../components/uploadField';
import { useFormHandler } from '../../utils/useFormHandler';
import { AdminProfileRequestData, updateAdminProfile } from '../../api/profile/adminProfile/admin';
import { useAuth, UserRoleProps } from '../../context/AuthContext';

interface BasicPersonalDetailsProps {
    onClose: () => void;
    profile?: any;
}

const { Option } = Select;

const BasicPersonalDetails: React.FC<BasicPersonalDetailsProps> = ({ onClose, profile }) => {
    const [form] = Form.useForm();
    const [isEditable, setIsEditable] = useState(false); // State to manage edit mode
    const{updateUser,user}=useAuth();

    const onFinish = useFormHandler(
        async (values: AdminProfileRequestData) => {
            profile = { profile: values };
            return await updateAdminProfile(profile);
        },
        form,
        () => {
            message.success('Profile updated successfully!');
            updateUser({
                ...user,
                profile:{
                    ...user!.profile,
                    profile_photo:form.getFieldValue('profile_photo')
                }
            } as UserRoleProps)
            setIsEditable(false);
            onClose();
        }
    );

    return (
        <div>
            <div className={styles.header}>
                <h2>Basic Personal Details</h2>
                <Button type="default" onClick={() => setIsEditable((prev) => !prev)}>
                    {isEditable ? 'Cancel' : 'Edit'}
                </Button>
            </div>

            <Form
                layout="vertical"
                className={styles.form}
                form={form}
                initialValues={profile.profile}
                onFinish={onFinish}
                disabled={!isEditable} // Disable the form if not editable
            >
                <div className="row">
                    {/* Left Column */}
                    <div className="col-sm-6 extended-column">
                        <Form.Item
                            label="Joining Date"
                            name="joining_date">

                            {isEditable ? (
                                <Input type="date" />
                            ) : (
                                <span>{profile.profile.joining_date || 'N/A'}</span>
                            )}
                        </Form.Item>

                        <Form.Item
                            label="Mother Name"
                            name="mother_name">

                            {isEditable ? (
                                <Input placeholder="Enter Mother Name" />
                            ) : (
                                <span>{profile.profile.mother_name || 'N/A'}</span>
                            )}
                        </Form.Item>

                        <Form.Item
                            label="Father Name"
                            name="father_name">

                            {isEditable ? (
                                <Input placeholder="Enter Father Name" />
                            ) : (
                                <span>{profile.profile.father_name || 'N/A'}</span>
                            )}
                        </Form.Item>

                        <Form.Item
                            label="Parent Mobile Number"
                            name="parent_mobile_number">

                            {isEditable ? (
                                <Input placeholder="Enter Parent Mobile Number" />
                            ) : (
                                <span>{profile.profile.parent_mobile_number || 'N/A'}</span>
                            )}
                        </Form.Item>

                        <Form.Item
                            label="Date of Birth"
                            name="dob">

                            {isEditable ? (
                                <Input type="date" />
                            ) : (
                                <span>{profile.profile.dob || 'N/A'}</span>
                            )}
                        </Form.Item>

                        <Form.Item
                            label="Gender"
                            name="gender">

                            {isEditable ? (
                                <Select placeholder="Select Gender">
                                    <Option value="male">Male</Option>
                                    <Option value="female">Female</Option>
                                    <Option value="other">Other</Option>
                                </Select>
                            ) : (
                                <span>{profile.profile.gender || 'N/A'}</span>
                            )}
                        </Form.Item>

                        <Form.Item
                            label="Nationality"
                            name="nationality">

                            {isEditable ? (
                                <Input placeholder="Enter Nationality" />
                            ) : (
                                <span>{profile.profile.nationality || 'N/A'}</span>
                            )}
                        </Form.Item>

                        <Form.Item
                            label="About"
                            name="about">

                            {isEditable ? (
                                <Input placeholder="Enter About" />
                            ) : (
                                <span>{profile.profile.about || 'N/A'}</span>
                            )}
                        </Form.Item>
                    </div>

                    {/* Right Column */}
                    <div className="col-sm-6 extended-column">
                        <Form.Item
                            label="Permanent Address"
                            name="permanent_address">

                            {isEditable ? (
                                <Input placeholder="Enter Permanent Address" />
                            ) : (
                                <span>{profile.profile.permanent_address || 'N/A'}</span>
                            )}
                        </Form.Item>

                        <Form.Item
                            label="Permanent Address Pincode"
                            name="permanent_address_pincode">

                            {isEditable ? (
                                <InputNumber placeholder="Enter Permanent Address Pincode" />
                            ) : (
                                <span>{profile.profile.permanent_address_pincode || 'N/A'}</span>
                            )}
                        </Form.Item>

                        <Form.Item
                            label="Correspondence Address"
                            name="correspondence_address">

                            {isEditable ? (
                                <Input placeholder="Enter Correspondence Address" />
                            ) : (
                                <span>{profile.profile.correspondence_address || 'N/A'}</span>
                            )}
                        </Form.Item>

                        <Form.Item
                            label="Correspondence Address Pincode"
                            name="correspondence_address_pincode">

                            {isEditable ? (
                                <InputNumber placeholder="Enter Correspondence Address Pincode" />
                            ) : (
                                <span>{profile.profile.correspondence_address_pincode || 'N/A'}</span>
                            )}
                        </Form.Item>

                        <Form.Item
                            label="Blood Group"
                            name="blood_group">

                            {isEditable ? (
                                <Input placeholder="Enter Blood Group" />
                            ) : (
                                <span>{profile.profile.blood_group || 'N/A'}</span>
                            )}
                        </Form.Item>

                        <UploadField disabled={!isEditable} required={false} form={form} name="profile_photo" title="Profile Photo" />

                    </div>
                </div>
                {isEditable && (
                    <div className={styles.formFooter}>
                        <Button type="primary" htmlType="submit">
                            {profile ? 'Update' : 'Submit'}
                        </Button>
                    </div>
                )}

            </Form>
        </div>
    );
};

export default BasicPersonalDetails;
