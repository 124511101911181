import { Button, Drawer, Form, Input, message, Select } from "antd";
import React, { useEffect, useState } from "react";
import styles from "../Batch/createBatchDrawer.module.scss";
import useDebounceValue from "../../../hooks/useDebouncedValue"; // Ensure this path is correct
import { useFormHandler } from "../../../utils/useFormHandler";
import useFilterSortPaginate from "../../../hooks/useFilterSortPaginate";
import { useSubjectList } from "../../../api/subjects/subjectAPI";
import { useFeeStructureList } from "../../../api/fees/feesApi";
import { FeeStructureProps } from "../../../types/feeStructure";
import ImageUpload from "../../../components/uploadField";
import { createBatch, updateBatch } from "../../../api/batch/batch";
import CustomDatePicker from "../../../components/CustomDatePicker";
import { disabledDate } from "../../../utils/utils";
import moment from "moment";
import dayjs from "dayjs";
import PageHeader from "../../../common/page-header/PageHeader";


interface AddBatchProps {
    onClose: () => void;
    open: boolean;
    batch?: any; // Optional prop for editing (adjust type based on your actual batch data structure)
}

// Define the type for subjects
interface Subject {
    id: number;
    name: string;
}

const AddBatch: React.FC<AddBatchProps> = ({ onClose, open, batch }) => {
    const [form] = Form.useForm();
    const { data: feesStructures } = useFeeStructureList(); // Fetch fee structure data from a hook
    const { data: subjects, loading, error, fetchWithParams } = useSubjectList();
    const { updateSearch } = useFilterSortPaginate(fetchWithParams);
    const { debouncedValue, handleDebounceSearchInput } = useDebounceValue(updateSearch, 2000);
    const [submitLoading, setSubmitLoading] = useState(false)
    const onFinish = useFormHandler(
        async (values) => {
            batch ?
                await updateBatch(batch.id, values) :
                await createBatch(values)
        },
        form,
        () => {
            message.success(batch ? 'Batch updated successfully!' : 'Batch created successfully!');
            onClose();
        },
        () => {

        },
        setSubmitLoading
    );

    useEffect(() => {
        if (batch) {
            form.setFieldsValue({
                ...batch,
                fee_structure: batch?.fee_structure?.id,
                subject: batch?.subject?.id,
                start_date_field: dayjs(batch?.start_date, "YYYY-MM-DD")
            })
        }
    }, [])


    return (
        <Drawer
            title={batch ? "Edit Batch" : "Add Batch"}
            onClose={onClose}
            open={open}
            width={"40%"}
            closeIcon={true}
            className={styles.createStudentDrawer}
        >
            <div className={styles.drawerMain}>
                <Form
                    layout={"vertical"}
                    form={form}
                    onFinish={onFinish}
                    style={{ maxWidth: 600 }}
                >
                    {/* Batch Fields */}
                    <Form.Item
                        label="Batch Name"
                        name="name"
                        rules={[{ required: true, message: "Please input batch name!" }]}
                    >
                        <Input placeholder="Enter batch name" />
                    </Form.Item>

                    <CustomDatePicker style={{ flex: 1 }}
                        rules={[{ required: true, message: "Please select batch start date!" }]}
                        form={form}
                        name={'start_date'}
                        field_name={'start_date_field'}
                        disabledDate={disabledDate}
                        label={"Batch Start Date"} />

                    <Form.Item
                        label="Select Subject"
                        name="subject"
                        rules={[{ required: true, message: "Please select at least one subject!" }]}
                    >
                        <Select
                            placeholder="Select subjects"
                            options={(subjects as Subject[]).map((subject: Subject) => ({
                                label: subject.name,
                                value: subject.id,
                            }))}
                            loading={loading}
                            filterOption={false}
                            showSearch
                            onSearch={handleDebounceSearchInput}
                            className={"multiSelect"}
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Select Fee Structure"
                        name="fee_structure"
                        rules={[{ required: true, message: "Please select fee structure!" }]}
                    >
                        <Select
                            placeholder="Select Structure"
                            filterOption={false}
                            showSearch
                            options={(feesStructures as FeeStructureProps[]).map((fees: FeeStructureProps) => ({
                                label: fees.structure_name,
                                value: fees.id,
                            }))}
                            onSearch={handleDebounceSearchInput}
                            className={"multiSelect"}
                            style={{ width: '100%' }}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Description"
                        name="description"
                        rules={[{ required: true, message: "Please input the Batch description!" }]}
                    >
                        <Input.TextArea rows={4} placeholder="Enter Batch description here" />
                    </Form.Item>

                    <ImageUpload form={form} name={'thumbnail'} />

                    <hr />
                    <PageHeader heading="Includes" />

                    <div style={{ display: "flex", gap: "16px" }}>
                        <Form.Item
                            label="Duration"
                            name="param_duration"
                            style={{ flex: 1 }}
                        >
                            <Input placeholder="Enter Total Duration" />
                        </Form.Item>


                        <Form.Item
                            label="Parameter 1"
                            name="param_1"
                            style={{ flex: 1 }}
                        >
                            <Input placeholder="Enter Parameter 1" />
                        </Form.Item>
                    </div>
                    <div style={{ display: "flex", gap: "16px" }}>
                        <Form.Item
                            label="Enrolled Students"
                            name="param_enrolled"
                            style={{ flex: 1 }}
                        >
                            <Input placeholder="Enter No Of Enrolled Students" />
                        </Form.Item>


                        <Form.Item
                            label="Parameter 2"
                            name="param_2"
                            style={{ flex: 1 }}
                        >
                            <Input placeholder="Enter Parameter 2" />
                        </Form.Item>


                    </div>

                    <div style={{ display: "flex", gap: "16px" }}>
                        <Form.Item
                            label="Chapters"
                            name="param_chapters"
                            style={{ flex: 1 }}
                        >
                            <Input placeholder="Enter No Of Chapters" />
                        </Form.Item>



                        <Form.Item
                            label="Parameter 3"
                            name="param_3"
                            style={{ flex: 1 }}
                        >
                            <Input placeholder="Enter No Of Parameter 3" />
                        </Form.Item>

                    </div>

                    <div style={{ display: "flex", gap: "16px" }}>
                        <Form.Item
                            label="Levels"
                            name="param_level"
                            style={{ flex: 1 }}
                        >
                            <Input placeholder="Enter No Of Levels" />
                        </Form.Item>

                        <Form.Item
                            label="Parameter 4"
                            name="param_4"
                            style={{ flex: 1 }}
                        >
                            <Input placeholder="Enter Parameter 4" />
                        </Form.Item>

                    </div>
                    <div style={{ display: "flex", gap: "16px" }}>

                        <Form.Item
                            label="Videos"
                            name="param_videos"
                            style={{ flex: 1 }}
                        >
                            <Input placeholder="Enter No of Videos " />
                        </Form.Item>

                        <Form.Item
                            label="Parameter 5"
                            name="param_5"
                            style={{ flex: 1 }}
                        >
                            <Input placeholder="Enter Parameter 5" />
                        </Form.Item>
                    </div>


                    <div className={styles.formFooter}>
                        <Button onClick={onClose}>Close</Button>
                        <Button loading={submitLoading} type="primary" htmlType="submit">
                            {batch ? "Update" : "Submit"}
                        </Button>
                    </div>
                </Form>
            </div>
        </Drawer>
    );
};

export default AddBatch;
