import React, { useEffect, useState } from 'react';

import { Dayjs } from 'dayjs';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { Button, Card, Select } from 'antd';
import styles from './analytics.module.scss';
import { XAxis, YAxis, ResponsiveContainer, AreaChart, Area } from 'recharts';
import { getDashboardMetrics } from '../../api/widgets/widgets';
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker


const Analytics = () => {
  const [metrics, setMetrics] = useState<any>();
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs] | null>(null);
  const [sortOption, setSortOption] = useState<string>('weekly');
  const [disableDateRange, setDisableDateRange] = useState<boolean>(true);


  const fetchMetrics = async (sortOption?: string, dateRange?: [Dayjs, Dayjs]) => {
    try {
      const validSortOption = sortOption && ['monthly', 'weekly', 'yearly', 'today', 'last7days', 'custom'].includes(sortOption)
        ? sortOption as 'monthly' | 'weekly' | 'yearly' | 'today' | 'last7days' | 'custom'
        : 'weekly';

      const formattedDateRange: [string, string] | undefined = dateRange
        ? [dateRange[0].format('YYYY-MM-DD'), dateRange[1].format('YYYY-MM-DD')]
        : undefined;
      const data = await getDashboardMetrics(validSortOption, formattedDateRange);
      setMetrics(data);
    } catch (error) {
      console.error('Error fetching dashboard metrics:', error);
    }
  };

  useEffect(() => {
    fetchMetrics();
  }, []);

  const handleSorting = async (value: string) => {
    setSortOption(value);
    if (['monthly', 'yearly', 'weekly','today'].includes(value)) {
      setDisableDateRange(true);
      setDateRange(null);
    } else {
      setDisableDateRange(false);
    }
  };

  const handleDateRangeChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    if (dates && dates[0] && dates[1]) {
      setDateRange([dates[0], dates[1]]);
    } else {
      setDateRange(null);
    }
  };

  const handleApplyFilter = () => {
    fetchMetrics(sortOption, dateRange || undefined);
  };

  // Only create cards if metrics data is available
  const cards = metrics
    ? [
      {
        title: 'Total Users',
        subtitle: metrics.total_users,
        description: `${metrics.total_users_percent_change}% up compared to last period.`,
        percentChange: metrics.total_users_percent_change,
      },
      {
        title: 'Total Enrollments',
        subtitle: metrics.total_enrollments,
        description: `${metrics.total_enrollments_percent_change}% up compared to last period.`,
        percentChange: metrics.total_enrollments_percent_change,
      },
      {
        title: 'Transactions',
        subtitle: metrics.transaction_count,
        description: `${metrics.transaction_count_percent_change}% ${metrics.transaction_count_percent_change > 0 ? 'up' : 'down'
          } compared to last period.`,
        percentChange: metrics.transaction_count_percent_change,
      },
      {
        title: 'Revenue',
        subtitle: `₹${metrics.revenue}`,
        description: `${metrics.revenue_percent_change}% up compared to last period.`,
        percentChange: metrics.revenue_percent_change,
      },
      {
        title: 'Lifetime Revenue',
        subtitle: `₹${metrics.lifetime_revenue}`,

      },
    ]
    : [];

  const analyticsData = [
    { day: 'Sat', value: 17 },
    { day: 'Sun', value: 16 },
    { day: 'Mon', value: 15 },
    { day: 'Tue', value: 18 },
    { day: 'Wed', value: 20 },
    { day: 'Thur', value: 19 },
    { day: 'Fri', value: 22 },
  ];



  return (
    <div>
      {/* Sort and Action Buttons Section */}
      <section className={styles.actionBtnsSection}>
        <div className="row">
          <div className="col-sm-7 col-12">
            <h4 className={styles.sectionHeading}>Analytics</h4>
          </div>
          <div className={styles.sort}>
            <Select
              onChange={handleSorting}
              className={styles.sortSelect}
              placeholder="Sort By"
              defaultValue="weekly"
            >
              <Select.Option value="today">Today</Select.Option>
              <Select.Option value="weekly">Weekly</Select.Option>
              <Select.Option value="monthly">Monthly</Select.Option>
              <Select.Option value="yearly">Yearly</Select.Option>
              <Select.Option value="custom">Custom Range</Select.Option>
            </Select>
            <RangePicker
              className={styles.datePicker}
              placeholder={['Start Date', 'End Date']}
              onChange={handleDateRangeChange}
              disabled={disableDateRange}
              value={dateRange}
            />

            <Button
              className={styles.applyFilterBtn}
              type="primary"
              onClick={handleApplyFilter}
            >
              Apply Filter
            </Button>
          </div>

        </div>
      </section>

      {/* Main Cards Section */}
      <section className={styles.cardsContainer}>
        <div className="row" >
          {cards.map((card, index) => (
            <div className="col-sm-2 col-12" key={index} style={{ flex: '0 0 20%', maxWidth: '20%', display: 'flex', flexDirection: 'column' }}>
              <Card className={styles.customCard} >
                <h2 className={styles.cardTitle}>{card.title}
                </h2>
                <h4 className={styles.cardSubtitle}>{card.subtitle}</h4>
                <p className={styles.cardDescription}>
                  {card.percentChange > 0 ? (
                    <ArrowUpOutlined
                      className={styles.arrowIcon}
                      style={{ color: 'green', marginRight: 8 }}
                    />
                  ) : card.percentChange < 0 ? (
                    <ArrowDownOutlined
                      className={styles.arrowIcon}
                      style={{ color: 'red', marginRight: 8 }}
                    />
                  ) : null}
                  {card.description}
                </p>
              </Card>
            </div>
          ))}
        </div>
      </section>

      {/* Card for Saturday to Monday Analytics */}
      <section className={styles.singleAnalyticsCardSection}>
        <Card className={styles.singleAnalyticsCard}>
          <h3 className={styles.cardHeader}>Analytics: Saturday to Monday</h3>
          <ResponsiveContainer width="100%" height={300}>
            <AreaChart data={analyticsData}>
              <XAxis dataKey="day" />
              <YAxis />
              <Area
                type="monotone"
                dataKey="value"
                stroke="#8884d8"
                fill="#8884d8"
              />
            </AreaChart>
          </ResponsiveContainer>
        </Card>
      </section>
    </div>
  );
};

export default Analytics;
