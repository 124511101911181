import styles from "./reports.module.scss";
import cn from "classnames";
import { Button, Input, Select, Tabs, Tag } from "antd";
import { FiSearch } from "react-icons/fi";
import { useState } from "react";
import { Table } from "antd";
import { FaFileInvoiceDollar, FaPlus } from "react-icons/fa6";

// import { STUDENT_REPORT_DATA } from "../../utils/constants";
import PageHeader from "../../common/page-header/PageHeader";
import AddReportDrawer from "../../components/report-drawer/AddReportDrawer";
import { formatIndianDate, formattedDateTime } from "../../utils/utils";
import { useTransactionList } from "../../api/payment/payment";
import useFilterSortPaginate from "../../hooks/useFilterSortPaginate";

const Reports = () => {

  const [openAddReportDrawer, setOpenAddReportDrawer] = useState<boolean>(false);
  const { data, loading, pagination, fetchWithParams } = useTransactionList();
  const { params, updateParams } = useFilterSortPaginate(fetchWithParams);

  const [activeTab, setActiveTab] = useState('1');

  const transactionReport = [
    { title: 'Student Name', dataIndex: 'student_name', key: 'student_name' },
    {
      title: 'Content Type',
      dataIndex: 'content_type',
      key: 'content_type',
      render: (text: string) => {
        let color = '';
        let label = '';

        switch (text) {
          case 'test_series':
            color = 'blue';
            label = 'Test Series';
            break;
          case 'course':
            color = 'green';
            label = 'Course';
            break;
          case 'batch':
            color = 'volcano';
            label = 'Batch';
            break;
          default:
            color = 'default';
            label = text;
        }
        return <Tag color={color}>{label}</Tag>;
      }
    },

    { title: 'Amount', dataIndex: 'amount', key: 'amount', render: (amount: number) => `₹${amount}` },
    { title: 'Transaction ID', dataIndex: 'payment_id', key: 'payment_id', },
    { title: 'Date', dataIndex: 'created', key: 'created', render: (text: string) => formatIndianDate(text), },
  ];

  const enrollementReport = [
    { title: 'Student Name', dataIndex: 'student_name', key: 'student_name' },
    { title: 'Phone Number', dataIndex: 'phone_number', key: 'phone_number' },
    { title: "Email", dataIndex: "email", key: "email", },
    { title: 'Date', dataIndex: 'date', key: 'date', render: (date: any) => formattedDateTime(date) },
   
  ];

  const notificationReport = [
    { title: 'Title', dataIndex: 'title', key: 'title' },
    { title: 'Description', dataIndex: 'description', key: 'description' },
    { title: 'Users', dataIndex: 'users', key: 'users' },
    { title: 'Date', dataIndex: 'date', key: 'date', render: (date: any) => formattedDateTime(date) },
    {
      title: "Action",
      render: (text: string, record: any) => {
        return (

          <div className={styles.tableActionBtns}>
            <Button title="Download Invoice" >
              <FaFileInvoiceDollar />
            </Button>

          </div>


        );
      },
    }

  ];

  const handleTransactionTableChange = (pagination: any) => {
  
    let paramsTransaction = {}

    paramsTransaction = {
      ...params,
      page: pagination.current,
    }

    updateParams(paramsTransaction);
  };

  return (
    <div className={cn("container-fluid")}>
      <PageHeader heading="Reports" />
      <section className={styles.actionBtnsSection}>
        <div className="row">
          <div className="col-sm-7 col-12">
            <div className={styles.filterDiv}>
              <Input placeholder="Search by name" prefix={<FiSearch />} />

              <Select className={styles.sortSelect} placeholder="Sort By">
                <Select.Option value="courseName">Student Name</Select.Option>
                <Select.Option value="newest">Active</Select.Option>
                <Select.Option value="lowToHigh">InActive</Select.Option>
              </Select>
            </div>
          </div>
          <div className="col-sm-5 col-12">
            <div className={styles.actionBtnDiv}>
              <Button
                onClick={() => setOpenAddReportDrawer(true)}
                type="primary"
              >
                <FaPlus /> Add Report
              </Button>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.tableSection}>
        <Tabs activeKey={activeTab} onChange={setActiveTab}>
          <Tabs.TabPane tab="Transaction Report" key="1">
            <section className={styles.tableSection}>
              <div className={styles.tableCard}>
                <Table

                  columns={transactionReport}
                  dataSource={data}
                  onChange={handleTransactionTableChange}
                  pagination={pagination}
                />
              </div>
            </section>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Enrollement Report" key="2">
            <section className={styles.tableSection}>
              <div className={styles.tableCard}>
                <Table
                  columns={enrollementReport}
                  // dataSource={feesRecord?.upcoming_fees}
                  // rowKey={(record) => `${record.student_name}-${record.due_date}`}
                  pagination={false}
                />
              </div>
            </section>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Notification Report" key="3">
            <section className={styles.tableSection}>
              <div className={styles.tableCard}>
                <Table
                  columns={notificationReport}
                  // dataSource={feesRecord?.paid_fees}
                  // rowKey={(record) => `${record.student_name}-${record.payment_date}`}
                  pagination={false}
                />
              </div>
            </section>
          </Tabs.TabPane>
        </Tabs>
      </section>
      <AddReportDrawer
        onClose={() => setOpenAddReportDrawer(false)}
        open={openAddReportDrawer}
      />
    </div>
  );
};
export default Reports;
