import React from 'react'
import styles from "./profile.module.scss"
import { Avatar } from 'antd';
import { useAuth } from '../../context/AuthContext';
import cn from "classnames"
import { BASE_URL } from '../../utils/apiClient';

const PersonalDetailsTab:React.FC<any>=()=> {

    const {user} = useAuth();
    
   
  return (
    <div className={cn(styles.detailsDiv,styles.personalInfoDiv)}>
        <div className={styles.profileImgdiv}>
            <Avatar 
                src=  {`${BASE_URL}${user?.profile?.profile_photo}`}
                style={{backgroundColor: '#674da1', verticalAlign: 'middle'}}
                alt="User Image"
                size={64}
                >
                    
                    
                    {!user?.profile?.profile_photo && user?.full_name.charAt(0).toUpperCase()}
            </Avatar>
        </div>
        <div className={styles.detailViewCard}>
            <div className={styles.labelValData}>
                <label>Name: </label>
                <span>{user?.full_name}</span>
            </div>
            <div className={styles.labelValData}>
                <label>Role: </label>
                <span>{user?.userRole}</span>
            </div>
            <div className={styles.labelValData}>
                <label>Contact: </label>
                <span>{user?.phone_number}</span>
            </div>
            <div className={styles.labelValData}>
                <label>Email: </label>
                <span>{user?.email}</span>
            </div>
        </div>
    </div>
  )
}
export default PersonalDetailsTab;