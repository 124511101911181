import cn from "classnames";
import { Button, Checkbox, Drawer, Form, Input, message, Table, Tag } from "antd";
import React, { useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { CiEdit } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
import PageHeader from "../../common/page-header/PageHeader";
import styles from "./batchSubjects.module.scss";
import { createSubject, deleteSubject, useSubjectList } from "../../api/subjects/subjectAPI";
import useFilterSortPaginate from "../../hooks/useFilterSortPaginate";
import { showErrors, useFormHandler } from "../../utils/useFormHandler";
import { Action, ModuleName, Role, UserRole } from "../../utils/Role";
import DeleteWarningModal from "../../common/delete-warning-modal/DeleteWarningModal";

const BatchSubjects = () => {
    const [form] = Form.useForm();
    const [openSubjectDrawer, setOpenSubjectDrawer] = useState<boolean>(false);
    const [selectedSubject, setSelectedSubject] = useState<any>(null);
    const [openSubjectDeleteWarning, setOpenSubjectDeleteWarning] = useState<boolean>(false);
    const { data: subjectData, pagination, loading, error, fetchWithParams } = useSubjectList();
    const {
        params,
        updateParams,
        resetParams,
    } = useFilterSortPaginate(fetchWithParams);
    const handleOpenDrawer = (subject: any) => {
        setSelectedSubject(subject)
        setOpenSubjectDrawer(true)
    }

    const handleCloseDrawer = () => {
        setSelectedSubject(null)
        setOpenSubjectDrawer(false)
        resetParams()
    }

    const handleCloseDeleteModal = () => {
        setSelectedSubject(null);
        setOpenSubjectDeleteWarning(false);
        resetParams()
    };
    const handleOpenDeleteModal = (record: any) => {
        setSelectedSubject(record);
        setOpenSubjectDeleteWarning(true);
    };


    const handleOnConfirmDeleteModal = async () => {
        try {
            await deleteSubject(selectedSubject.id)
            message.success("Subject deleted successfully")

        } catch (e) {
            showErrors(e)
        }
        handleCloseDeleteModal()
    }
    const onFinish = useFormHandler(
        createSubject, // For adding a new batch, use the createBatch API
        form,
        () => {
            message.success('Batch created successfully!');
            handleCloseDrawer()
            form.resetFields()
        }
    );

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (name: string) => name.toUpperCase(),
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            render: (description: string) => description.toUpperCase(),
        },
        {
            title: "Active",
            dataIndex: "is_active",
            key: "is_active",
            render: (is_active: string) => (
                <Tag color={is_active ? "green" : "red"}>
                    {is_active ? "Active" : "Inactive"}
                </Tag>
            ),
        },
        {
            title: "Action",
            render: (status: string, record: any) => (
                <div className={styles.tableActionBtns}>
                    <Role moduleName={ModuleName.BATCHES} action={Action.EDIT}>
                        <Button onClick={() => handleOpenDrawer(record)}>
                            <CiEdit />
                        </Button>
                    </Role>
                    <Role moduleName={ModuleName.BATCHES} action={Action.DELETE}>
                        <Button onClick={() => handleOpenDeleteModal(record)}>
                            <MdDeleteOutline />
                        </Button>
                    </Role>
                </div>
            ),
        },
    ];

    const handleAddSubjectTableChange = (pagination: any) => {

        let paramsTransaction = {}

        paramsTransaction = {
            ...params,
            page: pagination.current,
        }

        updateParams(paramsTransaction);
    };


    return (
        <div className={cn("container-fluid")}>
            <PageHeader heading="Subject Configurations" />
            <div className="row">
                <div className="col-sm-12 col-12">
                    <section className={styles.actionBtnsSection}>
                        <div className="row">

                            <div className="col-sm-12 col-12">
                                <div className={styles.actionBtnDiv}>
                                    <Button
                                        onClick={() => setOpenSubjectDrawer(true)}
                                        type="primary"
                                    >
                                        <FaPlus /> Add Subject
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className={styles.tableSection}>
                        <div className={styles.tableCard}>
                            <Table
                                loading={loading}
                                columns={columns}
                                dataSource={subjectData}
                                pagination={pagination}
                                onChange={handleAddSubjectTableChange}
                            />
                        </div>
                    </section>
                </div>
            </div>

            <DeleteWarningModal
                open={openSubjectDeleteWarning}
                onClose={handleCloseDeleteModal}
                onConfirm={handleOnConfirmDeleteModal}
                message={'Are you sure want to delete this Subject'}
            />
            {openSubjectDrawer &&
                <Drawer
                    title={selectedSubject ? "Update Subject" : "Add Subject"}
                    onClose={handleCloseDrawer}
                    open={openSubjectDrawer}
                    width={"30%"}
                    closeIcon={true}
                    className={styles.createStudentDrawer}
                >
                    <div className={styles.drawerMain}>
                        <Form form={form} layout={"vertical"} onFinish={onFinish} style={{ maxWidth: 400 }}
                            initialValues={selectedSubject}>
                            <Form.Item
                                label="Name"
                                name="name"
                                rules={[{ required: true, message: "Please input Subject name!" }]}
                            >
                                <Input placeholder="Enter Name" />
                            </Form.Item>
                            <Form.Item
                                label="Description"
                                name="description"
                                rules={[{ required: true, message: "Please input description name!" }]}
                            >
                                <Input placeholder="Enter description" />
                            </Form.Item>
                            <Form.Item
                                name="is_active"
                                valuePropName="checked"
                            >
                                <Checkbox>Active</Checkbox>
                            </Form.Item>
                            <div className={styles.formFooter}>
                                <Button onClick={handleCloseDrawer}>Close</Button>
                                <Button type="primary" htmlType="submit">
                                    {selectedSubject ? "Update" : "Create"}
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Drawer>
            }

        </div>
    );
};
export default BatchSubjects;
