import React, { useEffect, useState } from 'react';
import { Modal, Button, List } from 'antd';
import { MdCurrencyRupee } from 'react-icons/md';
import dayjs from 'dayjs';

import { getStudentFeesRecords } from '../api/batch/batch';

interface FeeReminderProps {
  onClose: () => void;
  message?: string;
}

const FeeReminder: React.FC<FeeReminderProps> = ({ onClose, message }) => {
  const [feesRecord, setFeesRecord] = useState<any>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const fetchRecords = async () => {
    const lastReminderDate = localStorage.getItem('lastReminderDate');
    const today = dayjs().format('YYYY-MM-DD');
    localStorage.setItem('lastReminderDate', today);

    if (!lastReminderDate || dayjs(today).diff(dayjs(lastReminderDate), 'day') < 3) {
      console.log("Reminder is due.");
    } else {

      try {

        const records: any = await getStudentFeesRecords();
        if (records.unpaid_fees && records.unpaid_fees.length > 0) {
          setIsModalVisible(true);
          setFeesRecord(records);
        }
      } catch (e) {
        console.error('Error fetching fees record:', e);
      }
    }
  };

  useEffect(() => {
    fetchRecords();
  }, []);

  const formattedDateTime = (date: string) => dayjs(date).format('DD MM YYYY');

  const handleModalClose = (): void => {
    const today = dayjs().format('YYYY-MM-DD');
    localStorage.setItem('lastReminderDate', today);
    setIsModalVisible(false);
    onClose();
  };


  return (
    <Modal
      title="Fee Reminder"
      open={isModalVisible} // Controlled by isModalVisible
      onOk={handleModalClose}
      onCancel={handleModalClose}
      footer={[
        <Button key="close" type="primary" onClick={handleModalClose}>
          Ok
        </Button>,
      ]}
    >
      <p>{message || 'You have unpaid fees. Please clear them to avoid penalties.'}</p>
      <List
        dataSource={feesRecord?.unpaid_fees}
        renderItem={(item: any) => (
          <List.Item>
            <List.Item.Meta
              title={<strong>{item.batch_name}</strong>}
              description={
                <>
                  <p>
                    Amount: <MdCurrencyRupee /> {item.amount}
                  </p>
                  <p>Due Date: {formattedDateTime(item.due_date)}</p>
                </>
              }
            />
          </List.Item>
        )}
      />
    </Modal>
  );
};

export default FeeReminder;
